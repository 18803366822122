.o-link {
  position: relative;
  display: inline-block;
  transition: transform 300ms $ease-out, opacity 200ms $ease-fade;

  // Extend clickable area without affecting underline position
  &--extended::after {
    @include extended-clickarea;
  }

  &--back {
    opacity: 0.5;
  }

  &--lang {
    text-transform: uppercase;
    opacity: 0.5;

    &::after {
      @include extended-clickarea($vrt-only: false, $large: true);
    }
  }

  // Underline
  &::before {
    @include underline;
    @include underline-inactive;
  }

  &:hover,
  &:focus,
  &--active {
    opacity: 1;

    &::before {
      @include underline-active;
    }
  }

  &:active {
    transform: scale(0.95);
  }
}

.o-link__arrow {
  display: inline-block;
  padding-right: 0.375rem;
  transition: transform 300ms $ease-out;
  transform: translateX(0.125rem);

  .o-link:hover &,
  .o-link:focus & {
    transform: translateX(0);
  }
}
