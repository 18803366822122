.l-office {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
}

.l-office__overlay {
  grid-column: 1/2;
  grid-row: 1/2;
  position: relative;
  z-index: 2;
  padding: $base-padding-x-s * 1.5 $base-padding-x-s;

  @media ($min-s) {
    padding: $base-padding-x-m;
  }

  @media ($min-ml) {
    padding: $base-padding-x-l;
  }
}

.l-office__image {
  grid-column: 1/2;
  grid-row: 1/2;
}
