.l-media {
  @include grid-4($gap: false);

  &--grid-gaps {
    @include grid-4;
  }
}

// Default w = 100, align = left
.l-media__el {
  grid-column: 1/5;

  &--w75 {
    grid-column: 1/4;

    &.l-media__el--right {
      grid-column: 2/5;
    }

    &.l-media__el--center {
      grid-column: 1/5;
      width: 75%;
      margin-left: auto;
      margin-right: auto;

      .l-media--grid-gaps & {
        width: calc(
          ((100% - (3 * #{$base-padding-x-s})) * 0.75) +
            (2 *#{$base-padding-x-s})
        );
        @media ($min-s) {
          width: calc(
            ((100% - (3 * #{$base-padding-x-m})) * 0.75) +
              (2 *#{$base-padding-x-m})
          );
        }

        @media ($min-ml) {
          width: calc(
            ((100% - (3 * #{$base-padding-x-l})) * 0.75) +
              (2 *#{$base-padding-x-l})
          );
        }
      }
    }
  }

  &--w50 {
    // 75% width for narrow viewports
    @media ($max-s) {
      grid-column: 1/4;

      &.l-media__el--center {
        grid-column: 1/5;
        margin-left: auto;
        margin-right: auto;
        width: 75%; // approximately ...
      }

      &.l-media__el--right {
        grid-column: 2/5;
      }
    }

    @media ($min-s) {
      grid-column: 1/3;

      &.l-media__el--center {
        grid-column: 2/4;
      }

      &.l-media__el--right {
        grid-column: 3/5;
      }
    }
  }
}
