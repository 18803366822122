.l-about-hero {
  position: relative;
  color: $color-white;
  //min-height: calc(100vh - 3rem);
}

.l-about-hero__hl {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include base-padding-x;
  padding-top: 10rem;
  padding-bottom: 5rem;

  @media ($min-ms) {
    padding-top: 14rem;
    padding-bottom: 8rem;
  }
}

.l-about-hero__color-cloud {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  overflow: hidden;

  > * {
    height: 0;
    width: 200%;
    padding-bottom: 200%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-35%, -25%) rotate(45deg);
    transform-origin: center;
  }
}
