.o-text-link {
  text-decoration: underline;
  text-decoration-color: rgba($color-blue, 0.5);
  transition: opacity 150ms $ease-fade, text-decoration-color 150ms $ease-fade;

  &:hover,
  &:focus {
    text-decoration-color: rgba($color-blue, 1);
    opacity: 0.5;
  }

  &:active {
    opacity: 1;
  }
}
