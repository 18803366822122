.l-footer {
  @include base-padding-x;
  padding-top: 7.5rem;
  padding-bottom: 3rem;

  @media ($max-ms) {
    > * + * {
      margin-top: 3rem;
    }
  }

  @media ($min-ms) {
    padding-top: 11rem;
    padding-bottom: 3.5rem;
    display: flex;
    justify-content: space-between;
  }
}

.l-footer__nav {
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'nav__pages nav__lang'
    'nav__pages nav__legal';
  grid-column-gap: 4rem;
}

.l-footer__pages {
  &:nth-child(1) {
    grid-area: nav__pages;
  }

  &:nth-child(2) {
    grid-area: nav__legal;
    align-self: end;
  }

  > * + * {
    margin-top: 0.125rem;
  }
}

.l-footer__lang {
  grid-area: nav__lang;

  > * + * {
    margin-left: 1rem;
  }
}

.l-footer__social {
  display: flex;

  > * + * {
    margin-left: 0.75rem;
  }
}
