.l-ecosystem {
}

.l-ecosystem__reveal {
  overflow: hidden;

  @media ($min-s) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.l-ecosystem__text {
  grid-column: 1/3;
  grid-row: 1/2;
  text-align: center;
  align-self: center;
  position: relative;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;

  @media ($max-s) {
    padding-left: $base-padding-x-s;
    padding-right: $base-padding-x-s;
  }

  @media ($min-s) and ($max-m) {
    width: 60%;
  }

  @media ($min-m) {
    max-width: 640/16 * 1rem;
  }

  // Anim
  @media ($min-s) {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 500ms $ease-fade 300ms, transform 1000ms $ease-out 300ms;

    .l-ecosystem__reveal--active & {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.l-ecosystem__hl {
  span {
    display: block;
  }
}

.l-ecosystem__image-left {
  grid-column: 1/2;
  grid-row: 1/2;

  @media ($max-s) {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
  }

  @media ($min-s) {
    padding-left: 50%;
  }

  // Anim
  @media ($min-s) {
    transform: translateX(-2rem);
    transition: transform 1400ms $ease-out;

    .l-ecosystem__reveal--active & {
      transform: translateX(-65%);
    }
  }

  @media ($min-m) {
    .l-ecosystem__reveal--active & {
      transform: translateX(-410/16 * 1rem);
    }
  }
}

.l-ecosystem__image-right {
  grid-column: 2/3;
  grid-row: 1/2;

  @media ($max-s) {
    margin-top: 3rem;
    width: 100%;
    margin-right: -20%;
    margin-left: auto;
  }

  // Anim
  @media ($min-s) {
    transform: translateX(2rem);
    transition: transform 1400ms $ease-out;

    .l-ecosystem__reveal--active & {
      transform: translateX(65%);
    }
  }

  @media ($min-m) {
    .l-ecosystem__reveal--active & {
      transform: translateX(400/16 * 1rem);
    }
  }
}

.l-ecosystem__image {
  width: 80%;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;

  @media ($min-ms) {
    margin-top: 7rem;
    width: 60%;
  }
}
