.o-more-link {
  position: relative;
  display: inline-block;
  transition: transform 300ms $ease-out, opacity 200ms $ease-fade;

  // Extend clickable area without affecting underline position
  &:not(&--span)::after {
    @include extended-clickarea;
  }

  // Underline
  &::before {
    @include underline;
    @include underline-active;
  }

  &:hover,
  &:focus {
    opacity: 0.5;

    &::before {
      @include underline-inactive;
    }
  }

  // link inside headline with extended click area
  // span link inside paragraph
  &--span {
    *:hover ~ * > &,
    *:focus-within ~ * > & {
      opacity: 0.5;

      &::before {
        @include underline-inactive;
      }
    }
  }

  &:active {
    transform: scale(0.95);
  }
}
