.l-outcome {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 6rem;

  @media ($min-ms) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}

.l-outcome__wrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-areas:
    'outcome__hl outcome__hl outcome__hl'
    'outcome__quote outcome__quote outcome__quote'
    'outcome__service-list outcome__service-list outcome__service-list'
    'outcome__awards outcome__awards outcome__awards';
  grid-column-gap: $base-padding-x-s;

  @media ($min-s) {
    grid-column-gap: $base-padding-x-m;
  }

  @media ($min-ms) {
    grid-template-areas:
      'outcome__hl outcome__hl .'
      'outcome__quote outcome__quote outcome__service-list'
      'outcome__awards outcome__awards outcome__service-list';
  }

  @media ($min-m) {
    grid-column-gap: $base-padding-x-l;
  }
}

.l-outcome__hl {
  grid-area: outcome__hl;
  color: $color-orange;
  line-height: $line-height-flush;
  margin-bottom: 2.5rem;

  @media ($min-ms) {
    margin-bottom: 3.5rem;
  }

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-case-hl-outcome, 1),
      $ml: nth($font-size-case-hl-outcome, 2)
    )
  );
}

.l-outcome__quote {
  grid-area: outcome__quote;
}

.l-outcome__service-list {
  grid-area: outcome__service-list;

  @media ($max-ms) {
    margin-top: 3rem;
  }
}

.l-outcome__awards {
  grid-area: outcome__awards;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  margin-left: -2.5rem;
  margin-top: 2rem;

  @media ($min-ms) {
    margin-top: 3.5rem;
    margin-left: -3.5rem;
  }

  > * {
    margin-left: 2.5rem;
    margin-top: 2.5rem;

    @media ($min-ms) {
      margin-left: 3.5rem;
    }
  }

  * {
    height: 3rem;
    width: auto;

    @media ($min-ms) {
      height: 4rem;
    }
  }
}

.l-outcome__color-cloud {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  overflow: hidden;
  opacity: 1;

  > * {
    height: 0;
    width: 200%;
    padding-bottom: 200%;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -20%) rotate(-45deg);
    transform-origin: center;
  }
}
