// .c-nav {
// }

.c-nav__toggle {
  box-sizing: content-box;
  width: 32/16 * 1rem;
  height: 32/16 * 1rem;
  padding: 0.5rem;
  position: relative;
  z-index: 10;
  right: -0.5rem;

  @media ($min-ms) {
    display: none;
  }
}

.c-nav__wrapper {
  @media ($max-xs) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  @media ($max-ms) {
    @include base-padding-x;
    padding-top: 4rem;
    padding-bottom: 4rem;
    font-size: 18/16 * 1rem;
    text-align: center;
    position: absolute;
    z-index: 8;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    height: calc(100vh + 2rem);
    background-color: $color-gray-light;
    box-shadow: 0 2px 20px rgba($color-blue, 0.2);
    visibility: hidden; // A11Y: Prevent focus if nav is hidden
    opacity: 0;
    color: $color-blue; // if white header, mobile nav should still be blue
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }

  @media (min-height: 500/16*1em) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.c-nav__list {
  &--primary {
    @media ($max-ms) {
      font-size: 32/16 * 1rem;

      > * + * {
        margin-top: 0.375rem;
      }
    }

    @media ($min-ms) {
      font-size: 22/16 * 1rem;
      transform-origin: center right;

      .l-header--sticky & {
        transform: scale(0.9);
      }
    }
  }

  &--secondary {
    opacity: 0.5;

    @media ($max-ms) {
      margin-top: 2.25rem;

      > * + * {
        margin-top: 0.375rem;
      }
    }

    @media ($min-ms) {
      display: none;
    }
  }
}

.c-nav__lang {
  @media ($max-ms) {
    margin-top: 3rem;

    > * + * {
      margin-left: 1rem;
      //margin-left: 0.75rem;
    }
  }

  @media ($min-ms) {
    display: none;
  }
}

.c-nav__item {
  @media ($min-ms) {
    display: inline-block;
    //margin-left: 2.125rem;
    margin-left: 1.75rem;

    &--hidden {
      display: none;
    }
  }
}
