.c-contact {
}

.c-contact__email {
  margin-top: 2rem;

  @media ($min-ms) {
    margin-top: 2.5rem;
  }
}

.c-contact__phone {
  margin-top: 2rem;

  @media ($min-ms) {
    margin-top: 2.5rem;
  }

  .c-contact__email + & {
    margin-top: 0;
  }
}

.c-contact__social {
  display: flex;
  margin-top: 2.875rem;

  @media ($min-ms) {
    margin-top: 3.375rem;
  }

  > * + * {
    margin-left: 1rem;
  }
}
