.l-clients {
  @include grid-4;
  grid-row-gap: 1rem;
}

.l-clients__hl {
  grid-column: 1/5;

  @media ($min-ms) {
    grid-column: 1/1;
  }
}

.l-clients__text {
  grid-column: 1/5;

  @media ($min-ms) {
    grid-column: 2/5;
  }

  @media ($min-ml) {
    grid-column: 2/4;
  }
}
