.l-text {
  > * + * {
    margin-top: 2.5rem;

    @media ($min-ms) {
      margin-top: 4rem;
    }
  }
}

.l-text__hl {
  span {
    display: block;
  }

  &--left {
    &.l-text__hl--offset {
      span:nth-child(even) {
        margin-left: 2em;

        @media ($min-s) {
          margin-left: 3em;
        }
      }
    }
  }

  &--center {
    text-align: center;

    &.l-text__hl--offset {
      span:nth-child(odd) {
        margin-right: 1em;

        @media ($min-s) {
          margin-right: 1.5em;
        }
      }
      span:nth-child(even) {
        margin-left: 1em;

        @media ($min-s) {
          margin-left: 1.5em;
        }
      }
    }
  }

  &--right {
    text-align: right;

    &.l-text__hl--offset {
      span:nth-child(even) {
        margin-right: 2em;

        @media ($min-s) {
          margin-right: 3em;
        }
      }
    }
  }
}
