.o-overlay-hl_v1 {
  margin-left: -1/8 * 1em; // optical margin adjustment
  margin-right: -1/8 * 1em; // optical margin adjustment

  padding-left: 10vw;
  padding-top: 80px;
  text-transform: uppercase;

  line-height: $line-height-narrow;
  @include poly-fluid-sizing('font-size', ($s: nth($font-size-overlay-hl_v1, 1), $ml: nth($font-size-overlay-hl_v1, 2)));

  &--small {
    @include poly-fluid-sizing('font-size', ($s: nth($font-size-overlay-hl_v1-small, 1), $ml: nth($font-size-overlay-hl_v1-small, 2)));
  }

  &--large {
    line-height: $line-height-flush;
    @include poly-fluid-sizing('font-size', ($s: nth($font-size-overlay-hl_v1-large, 1), $ml: nth($font-size-overlay-hl_v1-large, 2)));
  }
}

@keyframes overlay-hl_v1-fade-in {
  from {
    transform: translateY(0.5em);
    opacity: 0;
  }
}

.o-overlay-hl_v1__line {
  display: block;

  .o-overlay-hl_v1--anim & {
    animation: overlay-hl_v1-fade-in 1300ms $ease-out both;
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        animation-delay: $i * 100ms + 300ms;
      }
    }
  }

  &--light {
    opacity: 0.5;
  }
}
