.l-grid {
  @media ($max-ms) {
    > * {
      width: 100%;

      &:nth-child(odd) {
        padding-right: $base-padding-x-m;
      }

      &:nth-child(even) {
        padding-left: $base-padding-x-m;
      }
    }

    // second grid
    & + & {
      > * {
        &:nth-child(odd) {
          padding-left: $base-padding-x-m;
          padding-right: 0;
        }

        &:nth-child(even) {
          padding-right: $base-padding-x-m;
          padding-left: 0;
        }
      }
    }
  }

  @media ($max-s) {
    > * {
      &:nth-child(odd) {
        padding-right: $base-padding-x-s;
      }

      &:nth-child(even) {
        padding-left: $base-padding-x-s;
      }
    }

    // second grid
    & + & {
      > * {
        &:nth-child(odd) {
          padding-left: $base-padding-x-s;
          padding-right: 0;
        }

        &:nth-child(even) {
          padding-right: $base-padding-x-s;
          padding-left: 0;
        }
      }
    }
  }

  @media ($min-ms) {
    display: flex;
    flex-wrap: wrap;

    > * {
      width: 50%;
    }
  }
}

.l-grid__item {
  &--text {
    align-self: flex-end;
  }

  &--sticky {
    @media ($min-ms) {
      align-self: flex-start;
      position: sticky;
      top: 0;
    }
  }

  &--offset {
    @media ($min-ms) {
      margin-left: auto;
    }
  }

  &--hl {
    display: grid;
    grid-template-columns: minmax(0, 1fr);

    > * {
      grid-column: 1/2;
      grid-row: 1/2;
    }
  }
}

.l-grid__text {
  @include base-padding-x;
  padding-bottom: 2rem;
}

.l-grid__hl {
  position: relative;
  z-index: 2;
  align-self: center;
  margin-left: $base-padding-x-s;

  @media ($min-s) {
    margin-left: $base-padding-x-m;
  }

  @media ($min-ms) {
    margin-left: -15/16 * 1em;
  }

  span {
    display: block;

    &:nth-child(even) {
      margin-left: 1.375em;
    }
  }
}
