.o-p--intro {
  @media ($min-s) {
    line-height: $line-height-medium;
  }

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-intro, 1),
      $ml: nth($font-size-intro, 2)
    )
  );
}

.o-p--case {
  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-case-p, 1),
      $ml: nth($font-size-case-p, 2)
    )
  );
}
