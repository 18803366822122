.l-home-hero {
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  > * {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  // Gradient overlay
  &::before {
    content: '';
    position: absolute;
    z-index: 3;
    width: 100%;
    opacity: 0.33;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      soft-gradient($color-gray-light)
    );
    height: 10rem;

    @media ($min-ms) {
      height: 12rem;
    }
  }
}

.l-home-hero__button {
  display: block;
  justify-self: center;
  align-self: center;
  position: relative;
  z-index: 3;
}

.l-home-hero__preview {
  position: relative;
  z-index: 2;
}

.l-home-hero__video {
  opacity: 0;
}
