.l-team {
  position: relative;
  padding-top: 5rem;

  @media ($min-ms) {
    padding-top: 8rem;
  }
}

// IE does not support grid auto placement, so use flexbox
.l-team__list {
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;

  @media ($min-ms) {
    margin-top: 4rem;
  }
}

.l-team__image {
  //mix-blend-mode: multiply;

  // widths
  width: 45%;
  width: calc((100% - #{$base-padding-x-s}) / 2);

  @media ($min-s) {
    width: calc((100% - #{$base-padding-x-m}) / 2);
  }

  @media ($min-ms) {
    width: 28%;
    width: calc((100% - #{$base-padding-x-m * 2}) / 3);
  }

  @media ($min-ml) {
    width: calc((100% - #{$base-padding-x-l * 2}) / 3);
  }

  // hrz margins
  @media ($max-s) {
    &:nth-child(even) {
      margin-left: $base-padding-x-s;
    }
  }

  @media ($min-s) and ($max-ms) {
    &:nth-child(even) {
      margin-left: $base-padding-x-m;
    }
  }

  @media ($min-ms) and ($max-ml) {
    &:nth-child(3n + 2),
    &:nth-child(3n + 3) {
      margin-left: $base-padding-x-m;
    }
  }

  @media ($min-ml) {
    &:nth-child(3n + 2),
    &:nth-child(3n + 3) {
      margin-left: $base-padding-x-l;
    }
  }

  // vrt margins
  @media ($max-ms) {
    &:nth-child(n + 3) {
      margin-top: 3rem;
    }
  }

  @media ($min-ms) {
    &:nth-child(n + 4) {
      margin-top: 7rem;
    }
  }
}

.l-team__color-cloud {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: none;

  // only Chrome, Safari, Opera, Android
  // Firefox has perf issues
  &:not(*:root) {
    opacity: 0.33;
    display: block;
  }

  //mix-blend-mode: multiply;

  > * {
    width: 100%;
    height: 100vw;
    position: sticky;
    overflow: hidden;
    top: 0;
    left: 0;

    > * {
      height: 0;
      width: 200%;
      padding-bottom: 200%;
      position: absolute;
      top: 0%;
      right: 0%;
      transform: translate(50%, -50%) rotate(0deg);
      transform-origin: center;
    }
  }
}
