// Colors
$color-white: rgb(255, 255, 255);
$color-gray-light: rgb(240, 240, 242);
$color-blue: rgb(39, 48, 88);
$color-orange: rgb(255, 120, 77);

// Breakpoints
$xs: 360 / 16 * 1em;
$s: 480 / 16 * 1em;
$ms: 720 / 16 * 1em;
$m: 1024 / 16 * 1em;
$ml: 1200 / 16 * 1em;
$l: 1440 / 16 * 1em;
$xl: 1680 / 16 * 1em;
$xxl: 1920 / 16 * 1em;
$xxxl: 2160 / 16 * 1em;

$max-xs: 'max-width: #{$xs - (1/16)}';
$min-xs: 'min-width: #{$xs}';
$max-s: 'max-width: #{$s - (1/16)}';
$min-s: 'min-width: #{$s}';
$max-ms: 'max-width: #{$ms - (1/16)}';
$min-ms: 'min-width: #{$ms}';
$max-m: 'max-width: #{$m - (1/16)}';
$min-m: 'min-width: #{$m}';
$max-ml: 'max-width: #{$ml - (1/16)}';
$min-ml: 'min-width: #{$ml}';
$max-l: 'max-width: #{$l - (1/16)}';
$min-l: 'min-width: #{$l}';
$max-xl: 'max-width: #{$xl - (1/16)}';
$min-xl: 'min-width: #{$xl}';
$max-xxl: 'max-width: #{$xxl - (1/16)}';
$min-xxl: 'min-width: #{$xxl}';
$max-xxxl: 'max-width: #{$xxxl - (1/16)}';
$min-xxxl: 'min-width: #{$xxxl}';

// Fonts
$font-family-default: 'Relative Web', -system-ui, -apple-system,
  BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;

// Font sizes
$font-size-default: 1rem;
$font-size-small: 14/16 * 1rem;
$font-size-intro: (22/16 * 1rem, 35/16 * 1rem);
$font-size-quote: (24/16 * 1rem, 38/16 * 1rem);
$font-size-hl1: (28/16 * 1rem, 45/16 * 1rem);
$font-size-hl2: (24/16 * 1rem, 30/16 * 1rem);
$font-size-hl3: (20/16 * 1rem, 22/16 * 1rem);

$font-size-overlay-hl: (36/16 * 1rem, 65/16 * 1rem);
$font-size-overlay-hl-small: (28/16 * 1rem, 38/16 * 1rem);
$font-size-overlay-hl-large: (50/16 * 1rem, 126/16 * 1rem);

//Jie modified for career page
$font-size-overlay-hl_v1: (70/16 * 1rem, 90/16 * 1rem);
$font-size-overlay-hl_v1-small: (22/16 * 1rem, 28/16 * 1rem);
$font-size-overlay-hl_v1-large: (36/16 * 1rem, 52/16 * 1rem);

$font-size-service-title: (36/16 * 1rem, 70/16 * 1rem, 90/16 * 1rem);
$font-size-service-title-list: (48/16 * 1rem, 60/16 * 1rem);
$font-size-service-title-small: (22/16 * 1rem, 28/16 * 1rem);

$font-size-case-p: ($font-size-default, 24/16 * 1rem);
$font-size-case-hl1: (36 / 16 * 1rem, 84/16 * 1rem);
$font-size-case-hl2: (28 / 16 * 1rem, 56/16 * 1rem);
$font-size-case-hl-outcome: (42 / 16 * 1rem, 74/16 * 1rem);

// Line heights
$line-height-default: 1.45;
$line-height-medium: 1.35;
$line-height-narrow: 1.2;
$line-height-extra-narrow: 1.1;
$line-height-flush: 1;

// Letter spacings
$letter-spacing-default: 0.0625em;
$letter-spacing-medium: 0.075rem;
$letter-spacing-wide: 0.1rem;

// Paddings
$base-padding-x-s: 1rem;
$base-padding-x-m: 2.5rem;
$base-padding-x-l: 4.25rem;

// Max widths
$max-width-s: 740/16 * 1rem;
$max-width-m: 860/16 * 1rem;
$max-width-l: 1100/16 * 1rem;

// Easings
$ease-in: cubic-bezier(0, 0, 0.2, 1);
$ease-out: cubic-bezier(0.25, 0.5, 0, 1);
$ease-in-out: cubic-bezier(0.6, 0, 0, 1);
$ease-in-out-back: cubic-bezier(0.95, 0.01, 0.4, 1.18);
$ease-fade: cubic-bezier(0, 0, 0.25, 1);
