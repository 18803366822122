.o-hl1 {
  line-height: $line-height-narrow;

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-hl1, 1),
      $ml: nth($font-size-hl1, 2)
    )
  );

  &--white {
    color: $color-white;
  }
}

.o-hl2 {
  line-height: $line-height-medium;

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-hl2, 1),
      $ml: nth($font-size-hl2, 2)
    )
  );
}

.o-hl3 {
  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-hl3, 1),
      $ml: nth($font-size-hl3, 2)
    )
  );
}

.o-hl2--service {
  text-transform: uppercase;
  letter-spacing: $letter-spacing-medium;
  line-height: $line-height-narrow;

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-intro, 1),
      $ml: nth($font-size-intro, 2)
    )
  );
}

.o-hl1--case {
  line-height: $line-height-extra-narrow;

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-case-hl1, 1),
      $ml: nth($font-size-case-hl1, 2)
    )
  );
}

.o-hl2--case {
  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-case-hl2, 1),
      $ml: nth($font-size-case-hl2, 2)
    )
  );
}
