.o-list {
  padding-left: 1em;

  li {
    position: relative;

    & + li {
      margin-top: 4/16 * 1em;
    }

    &::before {
      content: '–';
      position: absolute;
      left: -1em;
    }
  }

  &--ordered {
    padding-left: 20/16 * 1em;
    list-style-type: none;
    counter-reset: section;

    li::before {
      left: -20/16 * 1em;
      counter-increment: section;
      content: counter(section) '.';
    }
  }

  &--extended {
    li + li {
      margin-top: 10/16 * 1em;
    }
  }

  &--columns {
    @media ($min-ms) {
      columns: 2;
      column-gap: calc(#{$base-padding-x-m} + 1em); // incl list padding
    }

    @media ($min-ml) {
      column-gap: calc(#{$base-padding-x-l} + 1em); // incl list padding
    }
  }
}
