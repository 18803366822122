.l-columns {
  @include grid-4($gap: false);
  grid-row-gap: 3.5rem;

  &--flush {
    grid-row-gap: 0;
  }

  &--grid-gap-border {
    @include base-padding-x;
  }

  &--grid-gap-center {
    @include grid-4;

    @media ($max-ms) {
      padding-left: $base-padding-x-m;
      padding-right: $base-padding-x-m;
    }

    @media ($max-s) {
      padding-left: $base-padding-x-s;
      padding-right: $base-padding-x-s;
    }
  }

  &--center {
    align-items: center;
  }

  &--bottom {
    align-items: end;
  }
}

.l-columns__column {
  grid-column: 1/5;

  &:nth-child(1) {
    @media ($min-ms) {
      grid-column: 1/3;

      // vertical offsets
      .l-columns--offset-left.l-columns--offset-large & {
        margin-top: 70%;
      }
      .l-columns--offset-left.l-columns--offset-medium & {
        margin-top: 45%;
      }
      .l-columns--offset-left.l-columns--offset-small & {
        margin-top: 20%;
      }
    }
  }

  &:nth-child(2) {
    @media ($min-ms) {
      grid-column: 3/5;

      // vertical offsets
      .l-columns--offset-right.l-columns--offset-large & {
        margin-top: 70%;
      }
      .l-columns--offset-right.l-columns--offset-medium & {
        margin-top: 45%;
      }
      .l-columns--offset-right.l-columns--offset-small & {
        margin-top: 20%;
      }
    }
  }

  // Margin between image and text
  > * + * {
    margin-top: 2rem;

    @media ($min-ms) {
      margin-top: 2.5rem;
    }
  }
}

.l-columns__hl {
  span {
    display: block;
  }
}

// add padding depending on gaps
.l-columns__text {
  &--padding {
    @include base-padding-x();
  }

  @media ($min-ms) {
    &--padding-left {
      @include base-padding-x($right: false);
    }

    &--padding-right {
      @include base-padding-x($left: false);
    }
  }
}
