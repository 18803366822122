.l-integration {
}

.l-integration__hl-1 {
  @include base-padding-x;
  @include grid-4;

  > * {
    grid-column: 1/5;
    justify-self: end;

    @media ($min-s) {
      grid-column: 2/5;
      justify-self: start;
    }
  }

  span {
    display: block;

    &:nth-child(even) {
      margin-left: 1.375em;
    }
  }
}

.l-integration__video {
  margin-top: 2.5rem;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 90%;

  @media ($min-s) {
    width: 80%;
  }

  @media ($min-ms) {
    margin-top: 5rem;
    width: 60%;
  }

  // Video
  > *:nth-child(1) {
    position: relative;
    z-index: 2;
  }

  // Frame
  > *:nth-child(2) {
    margin-top: -1px;
  }
}

.l-integration__text-1 {
  @include base-padding-x;

  @media ($max-ms) {
    margin-top: 2rem;
  }

  @media ($min-ms) {
    margin-left: auto;
    width: 35%;
    box-sizing: content-box;
  }
}

.l-integration__text-2 {
  @include base-padding-x;

  @media ($max-ms) {
    margin-top: 5rem;
  }

  @media ($min-ms) {
    width: 40%;
    box-sizing: content-box;

    position: relative;
    z-index: 2;
  }
}

.l-integration__image {
  position: relative;
  margin-top: 20%;

  @media ($min-ms) {
    margin-top: 1rem;
  }
}

.l-integration__lines {
  position: absolute;
  width: 100%;
  z-index: 2;
  top: -30%;
  pointer-events: none;
  transition: opacity 500ms $ease-fade;
  opacity: 0;

  @keyframes integration-line-dash {
    to {
      stroke-dashoffset: 800;
    }
  }

  svg {
    display: block;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: rgba($color-blue, 0.33);

    > *:nth-child(-n + 4) {
      stroke: rgba($color-blue, 0.33);
      stroke-width: 5;
      stroke-dasharray: 0 14;
      animation: integration-line-dash 30s linear infinite;

      @media ($min-ms) {
        stroke-width: 2;
        stroke-dasharray: 0 6;
      }
    }

    > *:nth-child(n + 5) {
      stroke: $color-gray-light;
      stroke-width: 7;

      @media ($min-ms) {
        stroke-width: 4;
      }
    }

    > *:nth-child(5) {
      stroke-dasharray: 381.09454345703125;
      transition: stroke-dashoffset 1500ms $ease-out;
    }

    > *:nth-child(6) {
      stroke-dasharray: 617.9482421875;
      transition: stroke-dashoffset 2000ms $ease-out;
    }

    > *:nth-child(7) {
      stroke-dasharray: 267.3667907714844;
      transition: stroke-dashoffset 1000ms $ease-out 400ms;
    }

    > *:nth-child(8) {
      stroke-dasharray: 120.066650390625;
      transition: stroke-dashoffset 500ms $ease-out 500ms;
    }
  }
}
