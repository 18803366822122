.l-service-list {
  @include grid-4;
}

// IE grid has no auto placement, so use wrapper
// also autoprefixer cannon prefix negative integers
.l-service-list__wrapper {
  grid-column: 1/5;

  @media ($min-m) {
    grid-column: 2/5;
  }
}

.l-service-list__item {
  position: relative;
  width: max-content; // limit width and therefore extended click area to content
  max-width: 100%; // prevent overflow / force text wrap

  & + & {
    margin-top: 4rem;

    @media ($min-ms) {
      margin-top: 5.5rem;
    }
  }

  & + &--small {
    margin-top: 5rem;

    @media ($min-ms) {
      margin-top: 6.5rem;
    }
  }
}

.l-service-list__link {
  display: block;

  // extend click area
  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.l-service-list__more {
  margin-top: 1rem;
}
