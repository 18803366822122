// Base padding
@mixin base-padding-x($left: true, $right: true) {
  @if ($left) {
    padding-left: $base-padding-x-s;

    @media ($min-s) {
      padding-left: $base-padding-x-m;
    }

    @media ($min-ml) {
      padding-left: $base-padding-x-l;
    }
  }

  @if ($right) {
    padding-right: $base-padding-x-s;

    @media ($min-s) {
      padding-right: $base-padding-x-m;
    }

    @media ($min-ml) {
      padding-right: $base-padding-x-l;
    }
  }
}

// Grids
@mixin grid-4($gap: true) {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  @if ($gap) {
    grid-column-gap: $base-padding-x-s;

    @media ($min-s) {
      grid-column-gap: $base-padding-x-m;
    }

    @media ($min-ml) {
      grid-column-gap: $base-padding-x-l;
    }
  }
}

// Link
@mixin underline {
  content: '';
  width: 100%;
  height: 2/16 * 1em;
  background-color: currentColor;
  position: absolute;
  left: 0;
  bottom: 2/16 * 1em;
  border-radius: 1/16 * 1em;
  transform-origin: bottom left;
}

@mixin underline-inactive {
  transform: scaleX(0) scaleY(0.75);
  opacity: 0;
  transition: opacity 200ms $ease-fade, transform 200ms $ease-out;
}

@mixin underline-active {
  transform: scaleX(1) scaleY(0.75);
  opacity: 1;
  transition: opacity 300ms $ease-fade, transform 300ms $ease-out;
}

@mixin extended-clickarea($vrt-only: true, $large: false) {
  content: '';
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  position: absolute;

  $padding: if($large, 10, 6);
  top: -$padding/16 * 1rem;

  @if $vrt-only {
    padding: $padding/16 * 1rem 0;
    left: 0;
  } @else {
    padding: $padding/16 * 1rem;
    left: -$padding/16 * 1rem;
  }
}

// Soft gradient
@function soft-gradient($color) {
  $coords: (
    0: 1,
    19: 0.738,
    34: 0.541,
    47: 0.382,
    56.5: 0.278,
    65: 0.194,
    73: 0.126,
    80.2: 0.075,
    86.1: 0.042,
    91: 0.021,
    95.2: 0.008,
    98.2: 0.002,
    100: 0
  );

  $stops: null;

  @each $colorStop, $alphaValue in $coords {
    $stop: rgba($color, $alphaValue) percentage($colorStop/100);
    $stops: append($stops, $stop, comma);
  }

  @return $stops;
}

// Polyfluid sizing
@function list-sort($list) {
  $sortedlist: ();
  @while length($list) > 0 {
    $value: nth($list, 1);
    @each $item in $list {
      @if $item < $value {
        $value: $item;
      }
    }
    $sortedlist: append($sortedlist, $value, 'space');
    $list: list-remove($list, index($list, $value));
  }
  @return $sortedlist;
}

@function list-remove($list, $index) {
  $newList: ();
  @for $i from 1 through length($list) {
    @if $i != $index {
      $newList: append($newList, nth($list, $i), 'space');
    }
  }
  @return $newList;
}

@function map-sort($map) {
  $keys: list-sort(map-keys($map));
  $sortedMap: ();
  @each $key in $keys {
    $sortedMap: map-merge(
      $sortedMap,
      (
        $key: map-get($map, $key)
      )
    );
  }
  @return $sortedMap;
}

@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}

@function linear-interpolation($map) {
  $keys: map-keys($map);
  // The slope
  $m1: strip-units(map-get($map, nth($keys, 2)) - map-get($map, nth($keys, 1)));
  $m2: strip-units(nth($keys, 2) - nth($keys, 1));
  $m: $m1 / $m2;

  // The y-intercept
  $b1: map-get($map, nth($keys, 1));
  $b2: strip-units($m * nth($keys, 1));
  $b: $b1 - $b2;

  // Determine if the sign should be positive or negative
  $sign: '+';
  @if ($b < 0) {
    $sign: '-';
    $b: abs($b);
  }

  @return calc(#{$m * 100}vw #{$sign} #{$b});
}

// @include poly-fluid-sizing('font-size', (576px: 22px, 768px: 24px, 992px: 34px));
@mixin poly-fluid-sizing($property, $map) {
  // Get the number of provided breakpoints
  $length: length(map-keys($map));

  // Sort the map by viewport width (key)
  $map: map-sort($map);
  $keys: map-keys($map);

  // Minimum size
  #{$property}: map-get($map, nth($keys, 1));

  // Interpolated size through breakpoints
  @for $i from 1 through ($length - 1) {
    @media (min-width: nth($keys,$i)) {
      $value1: map-get($map, nth($keys, $i));
      $value2: map-get($map, nth($keys, ($i + 1)));
      // If values are not equal, perform linear interpolation
      @if ($value1 != $value2) {
        #{$property}: linear-interpolation(
          (
            nth($keys, $i): $value1,
            nth($keys, ($i + 1)): $value2
          )
        );
      } @else {
        #{$property}: $value1;
      }
    }
  }

  // Maxmimum size
  @media (min-width: nth($keys,$length)) {
    #{$property}: map-get($map, nth($keys, $length));
  }
}
