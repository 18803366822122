.l-career-hero {
  position: relative;
  color: $color-blue;
  //min-height: calc(100vh - 3rem);
}

.l-career-hero__hl {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include base-padding-x;
  padding-top: 10rem;
  padding-bottom: 5rem;

  @media ($min-ms) {
     padding-top: 14rem;
     padding-bottom: 8rem;
  }
}

.l-career-hero__color-cloud {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  overflow: hidden;

  > * {
    height: 0;
    width: 70%;
    padding-bottom: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-35%, -45%) rotate(45deg);
    transform-origin: center;
  }
}
// Jie modified intro

.l-career-des {
  line-height: $line-height-narrow;
  @include poly-fluid-sizing('font-size', ($s: nth($font-size-intro, 1), $ml: nth($font-size-intro, 2)));

  padding-bottom: 2rem;
  width: 80%;


  @media ($min-ms) {
    width: 50%;

  }
}

// Jie modified job description
.l-career-position {
  overflow: hidden;
  box-sizing:border-box;

  width: 100%;
  z-index: -2;
  background: $color-white;
  padding-left: 0;
  padding-bottom: 8rem;


  @media ($min-ms) {
    padding-left: 40vw;
  }
//position list accordion
input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

  &-accordion {
    width: 100vw;
    overflow: hidden;

    @media ($min-ms) {
      width: 60vw;
    }

  }

  .item {
    width:100%;
    overflow: hidden;
    border-bottom: 3px solid $color-blue;


    &-label {
      display: flex;
      justify-content: space-between;
      background: $color-white;
      align-items: center;
      cursor: pointer;

      padding: 1rem;

      h3 {
        @include poly-fluid-sizing(
          'font-size',
          (
            $s: nth($font-size-hl2, 1),
            $ml: nth($font-size-hl2, 2)
          )
        );
      }

      p {
        @include poly-fluid-sizing('font-size',
          ($s: $font-size-small, $ml: $font-size-default)
        );
      }

      &:hover {
        background: darken($color-white, 5%);
      }

      &::after {
        content: "\276F";
        width: 1rem;
        height: 1rem;
        text-align:left;
        transition: all 0.35s;
      }
    }
    &-content {
        max-height: 0;
        padding: 0 1em;
        color: $color-blue;
        background: white;
        transition: all 0.35s;
    }
  }
}


// :checked
input:checked {
  + .item-label {
    background: darken($color-white, 5%);

    &::after {
      transform: rotate(90deg) translate(9px,3px);
    }
  }

  ~ .item-content {
    max-height: none;
    padding:1em;
    p{
      padding: 1.4em 0;
    }
  }
}
