.l-design-process-teaser {
  @include base-padding-x;
  position: relative;
  padding-top: 6rem;
  padding-bottom: 2rem;

  @media ($min-ms) {
    padding-top: 12rem;
    padding-bottom: 4rem;
  }
}

.l-design-process-teaser__wrapper {
  position: relative;
}

.l-design-process-teaser__icon {
  max-width: 110/16 * 1rem;
  margin-left: auto;
  margin-right: auto;

  @media ($min-ms) {
    max-width: 200/16 * 1rem;
  }
}

.l-design-process-teaser__hl {
  text-align: center;
  position: absolute;
  z-index: 2;
  width: 100%;
  bottom: 20%;
  left: 0;

  @media ($min-ms) {
    bottom: 17%;
  }

  @include poly-fluid-sizing(
    'font-size',
    (
      $xs: nth($font-size-case-hl1, 1) * 0.75,
      $s: nth($font-size-case-hl1, 1),
      $ml: nth($font-size-case-hl1, 2)
    )
  );
}

.l-design-process-teaser__text {
  text-align: center;
  max-width: 420/16 * 1rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  margin-top: 1rem;

  @media ($min-ms) {
    margin-top: 2rem;
  }
}

.l-design-process-teaser__color-cloud {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;

  > * {
    height: 0;
    width: 170%;
    padding-bottom: 170%;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    transform-origin: center;

    @media ($min-ms) {
      width: 130%;
      padding-bottom: 130%;
    }
  }
}
