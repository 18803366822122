.o-social-link {
  display: block;
  box-sizing: content-box;
  width: 24/16 * 1rem;
  height: 24/16 * 1rem;
  padding: 0.5rem 0.25rem; // extend click area
  margin: -0.5rem -0.25rem; // extend click area
  transition: transform 300ms $ease-out, color 150ms $ease-fade;

  &--large {
    width: 30/16 * 1rem;
    height: 30/16 * 1rem;

    @media ($min-ms) {
      width: 36/16 * 1rem;
      height: 36/16 * 1rem;
    }
  }

  svg {
    width: 100%;
    height: auto;
    display: block;
    fill: currentColor;
  }

  &:hover,
  &:focus {
    color: $color-orange;
    //transform: scale(1.1);
  }

  &:active {
    transform: scale(0.95);
  }
}
