@function cloud-gradient($color) {
  $coords: (
    0: 1,
    10: 0.94,
    19: 0.86,
    34: 0.69,
    47: 0.52,
    56.5: 0.39,
    65: 0.28,
    73: 0.2,
    80.2: 0.135,
    86.1: 0.093,
    91: 0.051,
    95.2: 0.028,
    98.2: 0.012,
    100: 0
  );

  $stops: null;

  @each $colorStop, $alphaValue in $coords {
    $stop: rgba($color, $alphaValue) percentage($colorStop/100);
    $stops: append($stops, $stop, comma);
  }

  @return $stops;
}

.o-color-cloud {
  pointer-events: none;

  // // Noise overlay
  // filter: saturate(130%); bad perf in FF
  //
  // &::after {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   // mix-blend-mode: multiply; bad perf
  //   // background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><filter id='n' x='0' y='0'><feTurbulence type='fractalNoise' baseFrequency='1' stitchTiles='stitch'/></filter><rect width='100' height='100' filter='url(%23n)' opacity='1'/></svg>");
  //   // background-size: 100px;
  //   mask-image: radial-gradient(
  //     closest-side,
  //     rgba(0, 0, 0, 1) 70%,
  //     rgba(0, 0, 0, 0)
  //   );
  // }
}

[class^='o-color-cloud__g'] {
  position: absolute;
  width: 69%;
  height: 69%;
}

.o-color-cloud__g-1 {
  opacity: 0.57;
  bottom: 5%;
  right: 0;
  background: radial-gradient(closest-side, cloud-gradient(rgb(0, 66, 99)));
}

.o-color-cloud__g-2 {
  opacity: 0.75;
  bottom: 0;
  left: 15%;
  background: radial-gradient(closest-side, cloud-gradient(rgb(39, 78, 139)));
}

.o-color-cloud__g-3 {
  opacity: 0.7;
  top: 4%;
  left: 4%;
  background: radial-gradient(closest-side, cloud-gradient(rgb(255, 120, 75)));
}

.o-color-cloud__g-4 {
  opacity: 1;
  top: 0;
  left: 17%;
  background: radial-gradient(closest-side, cloud-gradient(rgb(187, 86, 92)));
}

.o-color-cloud__g-5 {
  opacity: 0.47;
  bottom: 8%;
  left: 0;
  background: radial-gradient(closest-side, cloud-gradient(rgb(39, 48, 88)));
}

.o-color-cloud__g-6 {
  opacity: 1;
  bottom: 8%;
  right: 9%;
  background: radial-gradient(closest-side, cloud-gradient(rgb(39, 78, 139)));
}

.o-color-cloud__g-7 {
  opacity: 0.41;
  top: 16%;
  left: 2%;
  background: radial-gradient(closest-side, cloud-gradient(rgb(82, 38, 149)));
}

.o-color-cloud__g-8 {
  mix-blend-mode: multiply;
  opacity: 0.77;
  top: 6%;
  left: 10%;
  background: radial-gradient(closest-side, cloud-gradient(rgb(187, 86, 92)));
}

.o-color-cloud__g-9 {
  mix-blend-mode: color;
  width: 66%;
  height: 66%;
  opacity: 0.55;
  top: 2%;
  right: 3%;
  background: radial-gradient(closest-side, cloud-gradient(rgb(39, 78, 139)));
}

.o-color-cloud__g-10 {
  mix-blend-mode: hue;
  width: 47%;
  height: 47%;
  opacity: 0.66;
  bottom: 24%;
  right: 5%;
  background: radial-gradient(closest-side, cloud-gradient(rgb(0, 66, 91)));
}
