.l-header {
  @include base-padding-x;
  padding-top: 1.5rem;
  padding-bottom: 0.375rem;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ($min-ms) {
    align-items: flex-end;
    padding-top: 3.25rem;
    padding-bottom: 1rem;
  }

  &--white {
    color: $color-white;
  }

  &--sticky {
    position: fixed;
    background-color: rgba($color-white, 0.9);
    backdrop-filter: blur(4px);
    top: -1rem;
    color: $color-blue;

    @media ($min-ms) {
      top: -2.75rem;
    }
  }
}

.l-header__logo {
  display: block;
  width: 84/16 * 1rem * 0.75;
  height: 41/16 * 1rem * 0.75;

  @media ($min-s) {
    width: 84/16 * 1rem;
    height: 41/16 * 1rem;
    transform-origin: center left;
    transition: scale 300ms $ease-out;

    .l-header--sticky & {
      transform: scale(0.75);
    }
  }

  @media ($min-ms) {
    transform-origin: bottom left;
  }

  svg {
    width: 100%;
    height: auto;
    display: block;
    fill: currentColor;
  }
}
