.o-service-title {
  line-height: $line-height-flush;
  letter-spacing: $letter-spacing-medium;
  text-transform: uppercase;
  width: max-content; // necessary for gradient start / end
  max-width: 100%; // prevent overflow / force text wrap

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-service-title, 1),
      $ml: nth($font-size-service-title, 2),
      $l: nth($font-size-service-title, 3)
    )
  );

  &--list {
    @include poly-fluid-sizing(
      'font-size',
      (
        $s: nth($font-size-service-title-list, 1),
        $ml: nth($font-size-service-title-list, 2)
      )
    );

    @supports (background-clip: text) {
      background-image: linear-gradient(
        to right,
        $color-blue 50%,
        $color-orange 100%
      );
      background-position: 0% 0%;
      background-size: 200%;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: background-position 700ms $ease-fade;

      &:hover {
        background-position: 100% 0%;
      }
    }
  }

  &--small {
    @include poly-fluid-sizing(
      'font-size',
      (
        $s: nth($font-size-service-title-small, 1),
        $ml: nth($font-size-service-title-small, 2)
      )
    );
  }

  span {
    display: block;
  }

  @keyframes service-hl-bg-anim {
    from {
      background-position: 0% 0%;
    }
    to {
      background-position: 100% 0%;
    }
  }

  &--bg {
    @supports (background-clip: text) {
      background-image: linear-gradient(
        to right,
        $color-blue 50%,
        $color-orange 100%
      );
      background-position: 0% 0%;
      background-size: 200%;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: service-hl-bg-anim 1500ms $ease-fade 200ms both;
    }
  }
}
