.c-slider {
  &--white {
    color: $color-white;
  }
}

// glide.js core styles
.glide {
  position: relative;
  width: 100%;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}

.glide__slides--dragging {
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
}

.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}

.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}

// custom styles
.glide__bullets {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 0.75em;
  padding-bottom: 0.75rem;

  @media ($min-s) {
    padding-right: 1em;
    padding-bottom: 1rem;
  }
}

.glide__bullet {
  background-color: currentColor;
  width: 5/16 * 1rem;
  height: 5/16 * 1rem;
  margin-left: 3/16 * 1rem;
  margin-right: 3/16 * 1rem;
  border-radius: 50%;
  opacity: 0.33;
  transition: opacity 200ms $ease-fade;

  @media ($min-s) {
    width: 6/16 * 1rem;
    height: 6/16 * 1rem;
    margin-left: 4/16 * 1rem;
    margin-right: 4/16 * 1rem;
  }

  &--active {
    opacity: 1;
  }
}

.glide__arrows {
  @media ($max-ms) {
    display: none;
  }
}

.glide__arrow {
  position: absolute;
  top: 50%;
  width: 24/16 * 1rem;
  height: 64/16 * 1rem;
  box-sizing: content-box;
  padding-left: 1rem;
  padding-right: 1rem;
  transform: translateY(-47%);
  transition: opacity 200ms $ease-fade;
  opacity: 1;

  &:hover {
    opacity: 0.3;
  }

  &:active {
    opacity: 1;
  }

  &--left {
    left: 0.125rem;
  }

  &--right {
    right: 0.125rem;
  }

  svg {
    width: 100%;
    height: auto;
    fill: none;
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}
