.l-case-teaser {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  position: relative; // extended click area bounds
  overflow: hidden;
}

.l-case-teaser__overlay {
  grid-column: 1/2;
  grid-row: 1/2;
  position: relative;
  z-index: 2;
  padding: $base-padding-x-s * 1.5 $base-padding-x-s;

  @media ($min-s) {
    padding: $base-padding-x-m;
  }

  &--large {
    @media ($min-ml) {
      padding: $base-padding-x-l;
    }
  }

  &--small {
    padding: $base-padding-x-s;

    @media ($min-s) {
      padding: $base-padding-x-m * 0.5;
    }

    @media ($min-ml) {
      padding: $base-padding-x-l * 0.5;
    }
  }

  &--right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
  }

  &--bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &--white {
    color: $color-white;
  }
}

.l-case-teaser__link {
  display: block;

  // extend click area
  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// only add margins if case teaser hl has siblings
.l-case-teaser__hl {
  * + & {
    margin-top: 0.825rem;
  }

  & + * {
    margin-top: 1rem;

    @media ($min-ms) {
      margin-top: 1.25rem;
    }
  }
}

.l-case-teaser__image {
  grid-column: 1/2;
  grid-row: 1/2;
  transition: transform 1000ms $ease-out;

  .l-case-teaser:hover & {
    transform: scale(1.05);
  }
}
