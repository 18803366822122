.c-design-process {
  @media ($min-ms) {
    display: flex;
    justify-content: space-between;
  }
}

$bar-height: 5px;

.c-design-process__item {
  list-style-type: none;

  @media ($max-ms) {
    max-width: 200/16 * 1rem;
    margin-left: auto;
    margin-right: auto;

    & + & {
      margin-top: 2.25rem;
    }
  }

  @media ($min-ms) {
    width: calc(20% - 1.25rem);
  }

  div {
    height: $bar-height;
    background-color: $color-blue;
    border-radius: $bar-height / 2;
  }

  h2 {
    line-height: $line-height-flush;
    letter-spacing: $letter-spacing-medium;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 0.25em;
    margin-bottom: 0.625em;

    // custom font sizes not listed in vars
    @include poly-fluid-sizing(
      'font-size',
      (
        $s: 2rem,
        $ms: 1.5rem,
        $m: 1.75rem,
        $ml: 1.75rem,
        $l: 2.625rem
      )
    );
  }

  p {
    line-height: $line-height-medium;
    font-size: $font-size-small;
  }
}

.c-design-process__item--1 {
  div {
    background: linear-gradient(
      to right,
      rgba(80, 92, 155, 0),
      rgb(73, 59, 144)
    );
  }
  h2 {
    color: rgb(38, 79, 140);
  }
}

.c-design-process__item--2 {
  div {
    background: linear-gradient(to right, rgb(79, 55, 145), rgb(116, 43, 142));
  }
  h2 {
    color: rgb(107, 38, 148);
  }
}

.c-design-process__item--3 {
  div {
    display: flex;
    justify-content: space-between;
    background: none;

    &::before,
    &::after {
      content: '';
      display: block;
      width: calc(50% - #{$bar-height * 1.4});
      height: $bar-height;
      border-radius: $bar-height / 2;
    }

    &::before {
      background: linear-gradient(
        to right,
        rgb(124, 49, 136),
        rgb(144, 61, 122)
      );
    }

    &::after {
      background: linear-gradient(
        to right,
        rgb(149, 64, 118),
        rgb(168, 76, 105)
      );
    }

    span {
      display: block;
      border-radius: $bar-height / 2;
      width: $bar-height;
      height: $bar-height;
      background: rgb(137, 66, 115);
    }
  }
  h2 {
    color: rgb(137, 66, 115);
  }
}

.c-design-process__item--4 {
  div {
    background: linear-gradient(to right, rgb(176, 81, 99), rgb(213, 99, 57));
  }
  h2 {
    color: rgb(186, 87, 92);
  }
}

.c-design-process__item--5 {
  div {
    background: linear-gradient(to right, rgb(220, 103, 47), rgb(255, 120, 0));
  }
  h2 {
    color: rgb(255, 120, 0);
  }
}
