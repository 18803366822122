.o-quote {
  quotes: '\00BB' '\00AB';

  p {
    line-height: $line-height-medium;

    @include poly-fluid-sizing(
      'font-size',
      (
        $s: nth($font-size-quote, 1),
        $ml: nth($font-size-quote, 2)
      )
    );
  }

  &--small p {
    line-height: inherit;
    font-size: nth($font-size-quote, 1);
  }
}

.o-quote p::before {
  content: open-quote;
}

.o-quote p::after {
  content: close-quote;
}

.o-quote__footer {
  margin-top: 1.5rem;

  @media ($min-s) {
    margin-top: 1.875rem;
  }
}
