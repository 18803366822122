.l-quote {
  @include grid-4($gap: false);
  grid-row-gap: 2rem;
}

.l-quote__image {
  grid-column: 1/4;

  .l-quote--reverse & {
    grid-column: 2/5;
  }

  @media ($min-ms) {
    grid-row: 1/2;
    grid-column: 1/3;

    .l-quote--reverse & {
      grid-column: 3/5;
    }
  }

  // hrz offset
  @media ($min-ms) {
    padding-left: 20%;

    .l-quote--reverse & {
      padding-left: 0;
      padding-right: 20%;
    }
  }

  // vrt offset
  @media ($min-ms) {
    padding-top: 6rem;

    .l-quote--bottom & {
      padding-top: 0;
      padding-bottom: 7.5rem;
    }
  }
}

.l-quote__text {
  grid-column: 2/5;

  .l-quote--reverse & {
    grid-column: 1/4;
  }

  @media ($min-ms) {
    grid-row: 1/2;
    grid-column: 3/5;

    .l-quote--reverse & {
      grid-column: 1/3;
    }

    .l-quote--bottom & {
      align-self: end;
    }
  }

  // hrz offset
  @media ($min-ms) {
    padding-left: 4rem;

    .l-quote--reverse & {
      padding-left: 0;
      padding-right: 4rem;
    }
  }

  @media ($min-ml) {
    padding-left: 6rem;

    .l-quote--reverse & {
      padding-left: 0;
      padding-right: 6rem;
    }
  }
}
