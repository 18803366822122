.o-play-button {
  border-radius: 50%; // hide svg overflow
  overflow: hidden; // hide svg overflow
  transition: transform 500ms $ease-out, opacity 200ms $ease-fade,
    visibility 200ms linear 200ms;
  transform: scale(0.8);
  visibility: hidden;
  opacity: 0;
  width: 100/16 * 1rem * 0.75;
  height: 100/16 * 1rem * 0.75;

  @media ($min-s) {
    width: 100/16 * 1rem;
    height: 100/16 * 1rem;
  }

  &:hover {
    transform: scale(1.075);
  }

  &:active {
    transform: scale(0.95);
  }

  svg {
    width: 100%;
    height: auto;
  }

  &--visible {
    transition: transform 500ms $ease-out, opacity 200ms $ease-fade,
      visibility 200ms linear 0ms;
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
}

.o-play-button__circle {
  fill: none;
  stroke: $color-white;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-linejoin: round;
  // stroke-dasharray: 288.5596008300781;
  // stroke-dashoffset: 288.5596008300781;
  // transform-origin: center;
  // transform: rotate(-90deg);
  // transition: transform 1200ms $ease-out, stroke-dashoffset 1500ms $ease-out;

  // .o-play-button--visible & {
  //   transform: rotate(90deg);
  //   stroke-dashoffset: 0;
  // }
}

.o-play-button__triangle {
  fill: $color-white;
  // transform-origin: center;
  // transform: scale(0.5);
  // opacity: 0;
  // transition: opacity 200ms $ease-fade 300ms, transform 1000ms $ease-out 300ms;

  // .o-play-button--visible & {
  //   transform: scale(1);
  //   opacity: 1;
  // }
}
