.o-overlay-hl {
  margin-left: -1/16 * 1em; // optical margin adjustment
  margin-right: -1/16 * 1em; // optical margin adjustment
  line-height: $line-height-narrow;

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-overlay-hl, 1),
      $ml: nth($font-size-overlay-hl, 2)
    )
  );

  &--small {
    @include poly-fluid-sizing(
      'font-size',
      (
        $s: nth($font-size-overlay-hl-small, 1),
        $ml: nth($font-size-overlay-hl-small, 2)
      )
    );
  }

  &--large {
    line-height: $line-height-flush;

    @include poly-fluid-sizing(
      'font-size',
      (
        $s: nth($font-size-overlay-hl-large, 1),
        $ml: nth($font-size-overlay-hl-large, 2)
      )
    );
  }
}

@keyframes overlay-hl-fade-in {
  from {
    transform: translateY(0.5em);
    opacity: 0;
  }
}

.o-overlay-hl__line {
  display: block;

  .o-overlay-hl--anim & {
    animation: overlay-hl-fade-in 1300ms $ease-out both;

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        animation-delay: $i * 100ms + 300ms;
      }
    }
  }

  &--light {
    opacity: 0.5;
  }
}
