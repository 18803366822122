.o-map {
  height: 50vw;
  max-height: 600/16 * 1rem;
  min-height: 350/16 * 1rem;
}

.o-map__wrapper {
  height: 100%;
  background-color: $color-gray-light;
}

.o-map__marker {
  width: 24px;
  height: 24px;
  background-color: $color-orange;
  border-radius: 50%;
  display: none;

  // Only show if Mapbox has initialized the marker
  &[style] {
    display: block;
  }
}
