::selection {
  background-color: $color-blue;
  color: $color-white;
}

html {
  background-color: $color-gray-light;
  color: $color-blue;
  font-family: $font-family-default;
  font-size: 1em;
  line-height: $line-height-default;
  letter-spacing: $letter-spacing-default;
  font-variant: no-common-ligatures;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // prevent long word wrap
  @media ($max-xs) {
    hyphens: auto;
  }

  // scale up
  // steps (fallback)
  @media ($min-xl) {
    font-size: 18/16 * 1em;
  }

  @media ($min-xxxl) {
    font-size: 20/16 * 1em;
  }

  // fluid
  @include poly-fluid-sizing(
    'font-size',
    (
      $xl: 1em,
      $xxxl: 20/16 * 1em
    )
  );
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
  // min-height: 100vh; // alternative: footer always below fold
}
