$font-url: '../fnt/relative-';
$font-family: 'Relative Web';

@font-face {
  font-family: $font-family;
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: url($font-url+'book.woff2') format('woff2'),
    url($font-url+'book.woff') format('woff');
}

@font-face {
  font-family: $font-family;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url($font-url+'medium.woff2') format('woff2'),
    url($font-url+'medium.woff') format('woff');
}

@font-face {
  font-family: $font-family;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url($font-url+'bold.woff2') format('woff2'),
    url($font-url+'bold.woff') format('woff');
}
