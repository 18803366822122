.c-cookie-consent {
  position: fixed;
  z-index: 20;
  bottom: 0.5rem;
  right: 0.5rem;
  margin-left: 0.5rem;
  font-size: $font-size-small;
  padding: 0.75rem $base-padding-x-s;
  background-color: $color-white;
  color: rgba($color-blue, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms $ease-fade, visiblity 0ms linear;

  &--visible {
    display: flex;
    opacity: 1;
    visibility: visible;
  }
}

.c-cookie-consent__button {
  padding: 0.75rem;
  margin: -0.75rem;
  margin-left: 1rem;
}
