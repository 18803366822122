.l-service-article {
  @include grid-4;
  grid-row-gap: 5rem;

  @media ($min-ms) {
    grid-row-gap: 7rem;
  }
}

.l-service-article__intro {
  grid-column: 1/5;
  grid-row: 1/2;

  @media ($min-m) {
    grid-column: 2/5;
  }

  > * + *:not(:empty) {
    margin-top: 2.5rem;
  }
}

.l-service-article__design-process {
  grid-column: 1/5;
  grid-row: 1/2;

  @media ($min-m) {
    padding: 8rem 4rem;
    grid-column: 1/5;
  }
}

.l-service-article__content {
  grid-column: 1/5;
  grid-row: 2/3;

  @media ($min-m) {
    grid-column: 2/5;
  }
}

.l-service-article__aside {
  @media ($max-m) {
    display: none;
  }

  @media ($min-m) {
    grid-column: 1/2;
    grid-row: 2/3;
  }
}

// Text indentation
// ... Grid does not work because no IE grid auto placement
@media ($min-ms) {
  .l-service-article__intro > p,
  .l-service-article__content > ul {
    // 3/4
    width: calc(
      ((100% - (3 * #{$base-padding-x-m})) * 0.75) + (2 *#{$base-padding-x-m})
    );
  }
}

@media ($min-m) {
  .l-service-article__intro > p,
  .l-service-article__content > ul {
    // 2/3
    width: calc(
      ((100% - (2 * #{$base-padding-x-m})) / 1.5) + #{$base-padding-x-m}
    );
  }
}
@media ($min-ml) {
  .l-service-article__intro > p,
  .l-service-article__content > ul {
    // 2/3
    width: calc(
      ((100% - (2 * #{$base-padding-x-l})) / 1.5) + #{$base-padding-x-l}
    );
  }
}
