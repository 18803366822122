.o-category {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-wide;

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: $font-size-small,
      $ml: $font-size-default
    )
  );

  &--light {
    opacity: 0.5;
  }
}
