// Utility classes

// Paddings padding-left, -right
.u-px--base {
  @include base-padding-x;
}
//padding-top
.u-pt--base {
  padding-top: 10rem;

  @media ($min-ms) {
    padding-top: 13rem;
  }
}

// Margins
.u-mt {
  &--1 {
    margin-top: 1.5rem;

    @media ($min-ms) {
      margin-top: 2rem;
    }
  }
  &--2 {
    margin-top: 3.5rem;

    @media ($min-ms) {
      margin-top: 4rem;
    }
  }
  &--3 {
    margin-top: 4rem;

    @media ($min-ms) {
      margin-top: 6rem;
    }
  }
  &--4 {
    margin-top: 4.5rem;

    @media ($min-ms) {
      margin-top: 8rem;
    }
  }
  &--5 {
    margin-top: 6rem;

    @media ($min-ms) {
      margin-top: 10rem;
    }
  }
}

.u-mx--auto {
  margin-left: auto;
  margin-right: auto;
}

// Max widths
.u-mw {
  &--1 {
    max-width: $max-width-s;
  }

  &--2 {
    max-width: $max-width-m;
  }

  &--3 {
    max-width: $max-width-l;
  }
}

// Overflow
.u-o--h {
  overflow: hidden;
}

// Text align
.u-ta--c {
  text-align: center;
}

// –––

// Helper classes
.u-text-spacing {
  // Default
  > * + * {
    margin-top: 1.5rem;
  }

  // Headlines (article)
  > .o-hl1 + * {
    margin-top: 2rem;
  }
  > .o-hl2 + * {
    margin-top: 1rem;
  }
  > .o-hl3 + * {
    margin-top: 1rem;
  }

  > * + .o-hl1,
  > * + .o-hl2 {
    margin-top: 4rem;
  }
  > * + .o-hl3 {
    margin-top: 3rem;
  }

  > .o-hl1 + .o-hl2 {
    margin-top: 3.5rem;
  }

  // Other elements
  > .o-quote + * {
    margin-top: 3.5rem;
  }
  > .o-figure + * {
    margin-top: 5rem;
  }

  > * + .o-quote {
    margin-top: 4rem;
  }
  > * + .o-figure {
    margin-top: 5rem;
  }

  // Paragraphs
  > .o-p--intro + * {
    margin-top: 2rem;
  }
  > * + .o-p--intro {
    margin-top: 2rem;
  }

  > .o-p--case + * {
    margin-top: 2rem;
  }
  > * + .o-p--case {
    margin-top: 2rem;
  }

  // Case headline
  > .o-hl1--case + * {
    margin-top: 2rem;

    @media ($min-ms) {
      margin-top: 4rem;
    }
  }
  > .o-hl2--case + * {
    margin-top: 1.5rem;

    @media ($min-ms) {
      margin-top: 2rem;
    }
  }

  // Service article
  > * + .o-hl2--service {
    margin-top: 5rem;

    @media ($min-ms) {
      margin-top: 6rem;
    }
  }

  > .o-p--intro + .o-list {
    margin-top: 2.5rem;

    @media ($min-ms) {
      margin-top: 3rem;
    }
  }
}

// JS Anim
.u-anim-hidden {
  opacity: 0;

  .no-js & {
    opacity: 1;
  }
}

// A11y
.u-a11y-hidden {
  // VO (Mac) bug not fixed: https://github.com/h5bp/html5-boilerplate/issues/1985
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
