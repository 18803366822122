.c-service-nav {
  padding-top: 0.5rem;
  position: sticky;
  top: 8rem;
}

.c-service-nav__list {
  margin-top: 1.375rem;
}

.c-service-nav__item {
  & + & {
    margin-top: 2/16 * 1rem;
  }

  & + &--small {
    margin-top: 1.25rem;
  }
}
