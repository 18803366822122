.l-case-list {
  display: flex;
  flex-wrap: wrap;

  @media ($min-ms) {
    margin-left: -$base-padding-x-m;
  }

  @media ($min-ml) {
    margin-left: -$base-padding-x-l;
  }
}

// IE does not support grid auto placement
.l-case-list__item {
  width: 100%;

  @media ($max-ms) {
    & + & {
      margin-top: 2.5rem;
    }
  }

  @media ($min-ms) {
    width: 47%;
    width: calc(50% - #{$base-padding-x-m});
    margin-left: $base-padding-x-m;

    &:nth-child(1n + 3) {
      margin-top: 5rem;
    }
  }

  @media ($min-ml) {
    width: calc(50% - #{$base-padding-x-l});
    margin-left: $base-padding-x-l;
  }
}
