.o-progress-bar {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  overflow: hidden;
  width: 100vw;
  height: 2px;
  background: linear-gradient(to left, soft-gradient($color-orange));
  transform-origin: left top;
  transform: scaleX(0);
}
