.l-case-hero {
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  // Can be overwritten in CMS
  --case-hero-bg: transparent;
  background-color: var(--case-hero-bg);

  &--white {
    color: $color-white;

    &::before {
      content: '';
      position: absolute;
      z-index: 2;
      width: 100%;
      opacity: 0.33;
      top: 0;
      left: 0;
      background-image: linear-gradient(to bottom, soft-gradient($color-blue));
      height: 10rem;

      @media ($min-ms) {
        height: 12rem;
      }
    }
  }
}

.l-case-hero__overlay {
  grid-column: 1/2;
  grid-row: 1/2;
  position: relative;
  z-index: 3;
  align-self: end;
  padding: $base-padding-x-s * 2 $base-padding-x-s;

  @media ($min-s) {
    padding: $base-padding-x-m $base-padding-x-m;
  }

  @media ($min-ms) {
    padding: $base-padding-x-m * 1.5 $base-padding-x-m;
  }

  @media ($min-ml) {
    padding: $base-padding-x-l * 1.25 $base-padding-x-l;
  }
}

.l-case-hero__image {
  grid-column: 1/2;
  grid-row: 1/2;
}
